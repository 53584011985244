import { faTractor, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

export const About = () => (
    <>
        <Container className='mb-5 text-center'>
            <div id="about" className="mb-5">

                <Row className="bg row bg-light align-items-center">
                    <Col sm={12} md={6} className="bg2">
                        <img src={`${process.env.PUBLIC_URL}/assets/judi-garden-leader.jpg`} alt='judi desire' className='img-fluid' />
                    </Col>
                    <Col sm={12} md={6} className="bg">
                        <h2 className="bg text-center">
                            Garden President &amp; Leader
                        </h2>
                        <h3 className="bg text-center">Judi Desire</h3>
                        <p className="bg text-center">Judi is a community leader who serves as president of the community
                            garden, overseeing farm stand operations and greenhouse projects to promote
                            sustainable practices and accessibility to local produce.</p>
                        <p className="bg text-center">
                            <a href="https://www.linkedin.com/in/jdesire/" target="_blank" rel="noopener noreferrer"
                                className="card-link text-center">LinkedIn</a>
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>

        <Container className='mb-5 text-center'>
            <Row className="bg row bg-light align-items-center py-5">
                <Col sm={12} md={6} className="bg2">
                    <img src={`${process.env.PUBLIC_URL}/assets/garden_recognition_awards.jpg`} alt='7th Annual Garden Recognition Awards' className='img-fluid' />
                </Col>
                <Col sm={12} md={6} className="bg">
                    <h2 className="bg text-center">7th Annual Garden Recognition Awards</h2>
                    <p>We are thrilled to announce that on April 18th, St. Nicholas Miracle Garden was awarded GreenThumb's Going the Extra Mile Award at their 7th Annual Garden Recognition Awards!</p>
                    <p>This prestigious award celebrates the outstanding work of GreenThumb Gardens, Bronx River Community Garden, El Garden, and Gil Lopez from Smiling Hogshead Ranch for making NYC greener, healthier, and more equitable.</p>
                    <p>We're proud to be recognized as a leading example within GreenThumb's network. Our farm stand, vibrant arts & music programs, hot box composting, and upcoming greenhouse project contribute to our mission.</p>
                    <p>We're incredibly grateful for our ongoing partnership with GreenThumb and look forward to using community gardening to bring people together and strengthen our neighborhoods!</p>
                </Col>
            </Row>
        </Container>

        <Container className='mb-5 text-center'>
            <Row className="bg row bg-light align-items-center py-5">
                <Col sm={12} md={6} className="bg2">
                    <img src={`${process.env.PUBLIC_URL}/assets/green_guerillas_panel.jpg`} alt='7th Annual Garden Recognition Awards' />
                </Col>
                <Col sm={12} md={6} className="bg">
                    <h2 className="bg text-center">Weaving Connection: How Community Gardens Strengthen NYC's Social Fabric</h2>
                    <p>I was honored to be one of the four panelists for Green Guerillas "Weaving Connection: How Community Gardens Strengthen NYC's Social Fabric" alongside Elizabeth O'Connor from Moore Jackson Garden in Queens, Ramon Andino, President of Bronx Land Trust, and Sandra Watanabe from Hollenback Community Garden in Brooklyn!</p>
                    <p>Discussing the future of our community gardens, feeding our communities and activating green spaces was inspiring.</p>
                    <p>Thanks to Council Member Shekar Krishnan (D-25) for the insightful fireside chat on our wins and next steps. Together, let's keep NYC green and vibrant!</p>
                </Col>
            </Row>
        </Container>

        <Container className='mb-5 text-center'>
            <Row className="bg row bg-light align-items-center py-5">
                <Col sm={12} md={6} className="bg2">
                    <img src={`${process.env.PUBLIC_URL}/assets/greenthumb_east_harlem_conference.jpg`} alt=' GreenThumb Annual GrowTogether Conference' className='img-fluid' />
                </Col>
                <Col sm={12} md={6} className="bg">
                    <h2 className="bg text-center">GreenThumb Annual GrowTogether Conference</h2>
                    <p>I am so grateful for the opportunity to join Kim from Pleasant Village Garden on the Project Planning panel at the GreenThumb Annual GrowTogether Conference!</p>
                    <p>We discussed our garden projects, from planning and organizing groups to exploring funding sources and community partners; we hope we provided some valuable insights!</p>
                </Col>
            </Row>
        </Container>


        <Container className='mb-5 pb-5 text-center bg-white'>
            <Row className="bg row bg-light align-items-center py-5">
                <Col sm={12} md={6}>
                    <img src={`${process.env.PUBLIC_URL}/assets/resilient_gardens_symposium_tourgroup_1.jpg`} alt='Resilient Gardens Symposium in New York City' className='img-fluid' />
                </Col>
                <Col sm={12} md={6}>
                    <img src={`${process.env.PUBLIC_URL}/assets/resilient_gardens_symposium_tourgroup_2.jpg`} alt='Resilient Gardens Symposium in New York City' className='img-fluid' />
                </Col>
            </Row>
            <Container>
                <h2 className="bg text-center">Resilient Gardens Symposium in New York City</h2>
                <p>Thank you to Cornell Cooperative Extension for including our garden on the <a href='https://gardening.cals.cornell.edu/resilient-gardens-symposium-nyc/' target='_blank' rel="noopener noreferrer">Resilient Gardens Symposium in New York City</a> - Harlem Walking Tour! We enjoyed sharing our greenhouse project and our efforts to tackle post-pandemic food insecurity. Special shoutout to Perl Egendorf for providing on-site soil testing and discussing soil health—such necessary knowledge for our community! Together, we're cultivating a healthier future.</p>
            </Container>
        </Container>

        <Container id="building-community" className="mb-4 bg-light py-5">
            <Row className="justify-content-md-center align-items-center">
                <Col sm={12} md={5} className='text-center'>
                    <FontAwesomeIcon icon={faUsers} size="5x" className='text-success' />
                    <h3 className="mb-3">
                        <a href="mailto:stnickgarden@gmail.com" title="Garden" target="_self">
                            <span className="text-success">Building Community</span>
                        </a>
                    </h3>
                </Col>
                <Col sm={12} md={7} className="bg">
                    <p>We are searching for passionate and enthusiastic individuals to join our team at the
                        community garden!</p>
                    <p>We welcome individuals interested in learning about sustainable gardening practices
                        and
                        want to help create an enjoyable and productive space for the community.</p>
                    <p>If you are interested in joining our team, check out our available opportunities and
                        contact us. We would be happy to tell you more about the opportunity. Thank you for
                        considering joining us!</p>
                    <ul className="list-inline">
                        <li className="list-inline-item"><a href="mailto:stnickgarden@gmail.com"
                            rel="noopener noreferrer" target="_blank">Join our team!</a></li>
                    </ul>
                </Col>
            </Row>
            <h4 className="my-4 text-center">Volunteer Opportunities</h4>
            <Container className='text-start'>
                <Row className="bg justify-content-md-center">
                    <Col sm={12} md={6}>
                        <ul>
                            <li><strong>Garden Co-Leader</strong> - collaborate with fellow volunteers to lead garden
                                projects, organize volunteer activities, and develop community engagement programming.
                            </li>
                            <li><strong>Treasurer/Fundraiser</strong> - securing financial resources to support our
                                community garden's growth, sustainability, and outreach initiatives.</li>
                            <li><strong>Educator / Program Coordinator</strong> - primary educators for children and
                                youth through
                                critical programs at our gardens.</li>
                            <li><strong>Grant Writer</strong> - assist in preparing grant template documents for
                                upcoming grant applications. Adapt grant documents to meet the requirements of different
                                supporter needs and portals.</li>
                        </ul>
                    </Col>
                    <Col sm={12} md={6}>
                        <ul>
                            <li><strong>Social Media Specialist</strong> - is responsible for creating and publishing
                                content on all social media platforms, including Facebook and Instagram, to grow an
                                audience, build garden programming awareness, and ultimately, boost community
                                supporters.</li>
                            <li><strong>Event Planner</strong> - supported garden events, discussed the events' needs,
                                and oversaw the setup, execution, and cleanup of events.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <p className='text-center'><a href="https://forms.gle/ZKb4aKfZGyysD2h39" target="_blank" rel="noopener noreferrer">Join our
                volunteering team</a></p>
        </Container>
        <Container className='mb-5 bg-light py-5 mb-5'>
            <Row className="justify-content-md-center align-items-center">
                <Col sm={12} md={5} className='text-center'>
                    <FontAwesomeIcon icon={faTractor} size="5x" className='text-success' />
                    <p><strong className="text-success"><span className="h3 text-center">Farm Stand &amp;
                        CSA:</span></strong><br /><span className="h4">June 6 - October 31 <br />Thursdays,
                            4PM - 7PM</span></p>
                </Col>
                <Col sm={12} md={6}>
                    <h3 className="mb-3 text-center">
                        <a href="https://uptowngoodfood.com" title="Uptown Good Food" target="_blank"
                            rel="noopener noreferrer">
                            <span className="text-success">Uptown Good Food</span>
                        </a>
                    </h3>
                    <p><a href="https://ubhealthyproject.org/" target="_blank" rel="noopener noreferrer">Uptown &amp; Bronx Healthy Project
                        501(c)(3)</a> serves as a way to promote
                        wellness initiatives, raise awareness about health issues, and generally show the
                        community we care about their well being in Harlem and The Bronx.</p>
                    <p><strong>We accept:</strong> SNAP/EBT/P-EBT, Health Bucks, FMNP, FreshConnect, and OTC Network
                    </p>
                    <p>Click below to learn more about the farm stand and community supported agriculture program.
                    </p>
                    <a href="https://airtable.com/appN3Tz0kQjNeRzb5/shrwiAhGQHE6MvC2j" target="_blank"
                        className="btn btn-warning btn-lg" rel="noopener noreferrer">Learn More</a>
                </Col>
            </Row>
        </Container>
    </>
)